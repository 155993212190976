import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {FaInstagram,FaFacebookF,FaWhatsapp} from 'react-icons/fa';
import {SiGooglemaps} from 'react-icons/si';
import {MdPlace} from 'react-icons/md';

const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  position: absolute;
  top:0px;
  margin: 0 auto;
  z-index: 10;
  position: sticky;
  z-index: 10;
   width:100%;
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 4vw;

  @media (max-width: 768px) {
    display: flex;
  }
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: black;
    transition: all 0.3s ease-in;
    top: 14.8vw;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #C6E7C9;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #C6E7C9;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
/* -------------------------- NavLinks styles -------------------------------- */

const NavItem = styled(Link)`
  text-decoration: none  !important;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bolder;
  color: white;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  position: relative;

  :after {
    text-decoration: none !important;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #98AFBA;
    height: 1px;
  }

  :hover {
    color: #9CCEE4;
    text-decoration: none !important;
    ::after {
      width: 100%;
      text-decoration: none !important;
      outline:none !important;
    }
  }

  @media (max-width: 768px) {
    text-decoration: none !important;

    margin: 30px 0;
    font-size: 1.8rem;
    z-index: 6;
  }
`


/* --------------------------------------------------------------------------- */

    let width ; 
    if (typeof window !== "undefined") {
        width = window.innerWidth;

      }
    // The width below which the mobile view should be rendered
    const breakpoint = 768;


class Navbar extends React.Component {
 // const [navbarOpen, setNavbarOpen] = useState(false)
  constructor(props) {
    super(props);
    this.state = {navbarOpen: false};
  }

  handleScroll  = (event) => {
    if(window.scrollY > 80) {
       console.log("gg"+this.props.color)
      // console.log("romaissas")
       const nav = document.querySelector(".navigation");
       nav.style.backgroundColor = this.props.color;
       if(width > breakpoint){
        nav.style.backgroundColor = "black";

        }
        else{
        nav.style.backgroundColor = "white";
        }
      
     

  } else {
        const nav = document.querySelector(".navigation");
        if(width > breakpoint){
        nav.style.backgroundColor = this.props.color;

        }
        else{
        nav.style.backgroundColor = "#181415";
        }



  }
    

  }


  
  componentDidMount() {
    const nav = document.querySelector(".navigation");
    nav.style.backgroundColor = this.props.color;

    document.addEventListener('scroll' , this.handleScroll)

  }
  componentWillUnmount() {
    document.removeEventListener('scroll',this.handleScroll);
  }
  render() {
  return (
    <Navigation className="navigation">
     <Link to="/">
     <img src={this.props.logo} className="nav-logo" alt="logo" />

     </Link>

      <Toggle
        navbarOpen={this.state.navbarOpen}
        onClick={() =>  this.setState({navbarOpen: !this.state.navbarOpen}) }
      >
        {this.state.navbarOpen ? <Hamburger className="ham" open /> : <Hamburger />}
      </Toggle>
      {this.state.navbarOpen ? (
        <Navbox>
            <>
              <NavItem  onClick={() =>  this.setState({navbarOpen: false}) }  to="/#home">Accueil</NavItem>
              <NavItem  onClick={() =>  this.setState({navbarOpen: false}) } to="/#aboutus">Qui sommes-nous ?</NavItem>
              <NavItem onClick={() =>  this.setState({navbarOpen: false}) } to="/#products">Produits</NavItem>
              <NavItem onClick={() =>  this.setState({navbarOpen: false}) } to="/#services">Services</NavItem>
              <div className="contact-button">
                  <NavItem  className="contact-link-button"  onClick={() =>  this.setState({navbarOpen: false}) } to="/#contact">Contact</NavItem>
              </div> 
              <ul id="social-icons" className="social-icons">
                                <li>
                                    <a className="link" target="_blank"  href="https://www.google.com/maps/place/Pharmacie+La+Roche/@36.3103074,6.6269261,15z/data=!4m5!3m4!1s0x0:0x380a5f3deb2c4757!8m2!3d36.3103074!4d6.6269261" rel="noreferrer">
                                        <MdPlace className="social-icon"/>          
                                    </a> 
                                </li>
                                <li>
                                    <a className="link" target="_blank"  href="https://www.facebook.com/pharmacielaroche" rel="noreferrer">
                                        <FaFacebookF className="social-icon"/>          
                                    </a> 
                                </li>
                                <li>
                                    <a className="link" target="_blank"  href="https://www.instagram.com/pharmacie.laroche/" rel="noreferrer">
                                        <FaInstagram className="social-icon"/>          
                                    </a> 
                                </li>
                                
                                <li>
                                    <a className="link" target="_blank"  href="https://api.whatsapp.com/send/?phone=213561890360&text&app_absent=0" rel="noreferrer">
                                        <FaWhatsapp className="social-icon"/>          
                                    </a> 
                                </li>
                            </ul>
            </> 
        </Navbox>
      ) : (
        <Navbox open>
           <>
              <NavItem   onClick={() =>  this.setState({navbarOpen: false}) } to="/#home">Accueil</NavItem>
              <NavItem  onClick={() =>  this.setState({navbarOpen: false}) } to="/#aboutus">Qui sommes-nous ?</NavItem>
              <NavItem onClick={() =>  this.setState({navbarOpen: false}) } to="/#products">Produits</NavItem>
              <NavItem onClick={() =>  this.setState({navbarOpen: false}) } to="/#services">Services</NavItem>
              <div className="contact-button">
                  <NavItem onClick={() =>  this.setState({navbarOpen: false}) } to="/#contact">Contact</NavItem>
              </div>
              <ul id="social-icons" className="social-icons">
                                <li>
                                    <a className="link" target="_blank"  href="https://www.google.com/maps/place/Pharmacie+La+Roche/@36.3103074,6.6269261,15z/data=!4m5!3m4!1s0x0:0x380a5f3deb2c4757!8m2!3d36.3103074!4d6.6269261" rel="noreferrer">
                                        <MdPlace className="social-icon"/>          
                                    </a> 
                                </li>
                                <li>
                                    <a className="link" target="_blank"  href="https://www.facebook.com/pharmacielaroche" rel="noreferrer">
                                        <FaFacebookF className="social-icon"/>          
                                    </a> 
                                </li>
                                <li>
                                    <a className="link" target="_blank"  href="https://www.instagram.com/pharmacie.laroche/" rel="noreferrer">
                                        <FaInstagram className="social-icon"/>          
                                    </a> 
                                </li>
                                
                                <li>
                                    <a className="link" target="_blank"  href="https://api.whatsapp.com/send/?phone=213561890360&text&app_absent=0" rel="noreferrer">
                                        <FaWhatsapp className="social-icon"/>          
                                    </a> 
                                </li>
                            </ul>
            </> 
        </Navbox>
      )}
    </Navigation>
  )}
}

export default Navbar
